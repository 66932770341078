import React, { useState } from "react";
import SideBar from "../../SideBar/SideBar";
import Header from "../../HeaderDashBoard/Header";
import { Upload, DatePicker } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { addBannerformData } from "../../Controller/Banner";
import AddbannerModel from "./Addbannerpopup";
// import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Banner.css";
import ImgCrop from "antd-img-crop";
import { Link } from "react-router-dom";
function AddBannerData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  // console.log(adminInformation);
  const [formData, setFormData] = useState({
    heading: "",
    title: "",
    description: "",
    banner_image_1: null,
    banner_image_2: null,
  });
  /*************************************************************
   * Function Name  : handleInputChange
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleFileChange4 = (fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({
        ...prevFormData,
        banner_image_1: file,
      }));
    }
  };
  const handleFileChange5 = (fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({
        ...prevFormData,
        banner_image_2: file,
      }));
    }
  };

  const handleInputChange = (e, field) => {
    const { name, value, files } = e.target;

    if (field === "banner_image") {
      const file = files.length > 0 ? files[0] : null;

      if (file) {
        // Update the state with the file object
        setFormData((prevVal) => ({
          ...prevVal,
          [field]: file,
        }));
      } else {
        // Handle the case where no file is selected (optional)
        setFormData((prevVal) => ({
          ...prevVal,
          [field]: null,
        }));
      }
    } else {
      setFormData((prevVal) => ({
        ...prevVal,
        [name]: value,
      }));
      setErrors((prevError) => ({
        ...prevError,
        [name]: "",
      }));
    }
  };

  /*************************************************************
   * Function Name  : handleSubmit
   * Purposs        : This function is used add social icon data
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.heading) {
        setErrors((preError) => ({
          ...preError,
          heading: "Heading is required.",
        }));
      } else if (!formData.title) {
        setErrors((preError) => ({
          ...preError,
          title: "Title is required.",
        }));
      } else if (!formData.description) {
        setErrors((preError) => ({
          ...preError,
          description: "Detail is required.",
        }));
      } else {
        setIsSubmitting(true);
        const updateResult = await addBannerformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === 200) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            page_name: "",
            title: "",
            description: "",
            banner_image_1: "",
            banner_image_2: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  /*************************************************************
          End Function
   *************************************************************/
  const { Dragger } = Upload;

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Add About Us Banner</h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group row">
                        {/* <div className="col-md-6">
                          <label htmlFor="fullname">
                            Page Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <select className="form-control common_category_input_for_all_section  "
                            id="page_name"
                            name="page_name"  onChange={handleInputChange}>
                            <option>Select Page Name</option>
                            <option value="About Us">About Us</option>
                          </select>
                        
                          {errors?.page_name ? (
                            <p style={{ color: "red" }}>{errors?.page_name}</p>
                          ) : (
                            ""
                          )}
                        </div> */}
                        <div className="col-md-6">
                          <label htmlFor="fullname">
                            Heading<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section  "
                            id="heading"
                            name="heading"
                            onChange={handleInputChange}
                            placeholder="Enter Heading"
                          />
                          {errors?.heading ? (
                            <p style={{ color: "red" }}>{errors?.heading}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="fullname">
                            Title<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section  "
                            id="title"
                            name="title"
                            onChange={handleInputChange}
                            placeholder="Enter Title"
                          />
                          {errors?.title ? (
                            <p style={{ color: "red" }}>{errors?.title}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleTextarea">
                          Details<span style={{ color: "red" }}>*</span>
                        </label>
                        <textarea
                          className="form-control common_category_input_for_all_section  "
                          id="description"
                          name="description"
                          onChange={handleInputChange}
                          rows="3"
                        ></textarea>
                        {errors?.description ? (
                          <p style={{ color: "red" }}>{errors?.description}</p>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="fullname">
                          Image
                        </label>

                        <ImgCrop rotate aspect={310 / 213}>
                          <Upload
                            className="avatar-uploader"
                            name="banner_image_1"
                            onChange={({ fileList }) =>
                              handleFileChange4(fileList)
                            } // Corrected handleFileChange function
                            listType="picture-card"
                            fileList={null} // You may want to pass formData.right_images here instead of null
                            beforeUpload={() => false}
                            style={{ width: "100%", height: "100%" }}
                          >
                            {formData.banner_image_1 ? (
                              <img
                                src={URL.createObjectURL(
                                  formData.banner_image_1
                                )}
                                alt="subcategory"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <div>
                                <svg
                                  className="icon"
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  data-icon="image"
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M0 64v384h512V64H0zm480 352H32V96h448v320z"
                                  ></path>
                                </svg>
                                <div className="ant-upload-text">Upload</div>
                              </div>
                            )}
                          </Upload>
                        </ImgCrop>
                      </div>
                      {/* <div className="form-group">
                        <label htmlFor="fullname">
                          Banner Image (Right side)
                        </label>

                        <ImgCrop rotate aspect={310 / 213}>
                          <Upload
                            className="avatar-uploader"
                            name="banner_image_2"
                            onChange={({ fileList }) =>
                              handleFileChange5(fileList)
                            } // Corrected handleFileChange function
                            listType="picture-card"
                            fileList={null} // You may want to pass formData.right_images here instead of null
                            beforeUpload={() => false}
                            style={{ width: "100%", height: "100%" }}
                          >
                            {formData.banner_image_2 ? (
                              <img
                                src={URL.createObjectURL(
                                  formData.banner_image_2
                                )}
                                alt="subcategory"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <div>
                                <svg
                                  className="icon"
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  data-icon="image"
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M0 64v384h512V64H0zm480 352H32V96h448v320z"
                                  ></path>
                                </svg>
                                <div className="ant-upload-text">Upload</div>
                              </div>
                            )}
                          </Upload>
                        </ImgCrop>
                      </div> */}
                      <div className="misrambhu_total_btn_for_gap">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                          style={{
                            backgroundColor: "#891621",
                            borderColor: "#5f1e24",
                          }}
                        >
                          {isSubmitting ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Submit"
                          )}
                        </button>
                        <Link to="/bannerList">
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{
                              backgroundColor: "#891621",
                              borderColor: "#5f1e24",
                            }}
                          >
                            <span>Cancel</span>
                          </button>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddbannerModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddBannerData;
