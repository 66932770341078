import React, { useState } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { InboxOutlined } from "@ant-design/icons";
import "../AllCssFile.css"; 
import { FaDesktop } from "react-icons/fa";
import ReactQuill from "react-quill";
import { Upload } from "antd";
import "react-quill/dist/quill.snow.css";
import { Link, useNavigate } from "react-router-dom";
import { addAboutformData } from "../Controller/Refund"; 
import AddblogModel from "./AddRefundpopup"; 
import ImgCrop from 'antd-img-crop';

function RefundCreate() {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
  const [errors, setErrors] = useState('');
  const navigate = useNavigate();
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen); 
  };
  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });

 

  const handleInputChange = (value, field) => {
    setFormData((prevVal) => ({
      ...prevVal,
      [field]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.title) {
        setErrors((preError) => ({
          ...preError,
          title: 'Title is required.',
        }));
      }else if (!formData.description) {
        setErrors((preError) => ({
          ...preError,
          description: 'Description is required.',
        }));
      }else{ 
        setIsSubmitting(true);
        const updateResult = await addAboutformData(formData, adminInformation.token);
        if(updateResult.data.status === true){
            setFormData((prevFormData) => ({
                ...prevFormData,
              title: "",
              description: "",
            }));
            setIsSubmitting(false);
            togglePopUp();
        }else{
          localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };


 


 
  
  
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <h5>Add Refund Policy Content </h5>
                </div>
                <div className="card shadow mb-4" style={{ backgroundColor: "#fff8f9" }}>
                  <div className="card-body">
                    <div className="responsive">
                      <form onSubmit={handleSubmit} encType="multipart/form-data">
                        <div className="form-group">
                          <label htmlFor="exampleInputText">Title<span style={{ color: "red" }}>*</span></label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="title"
                            name="title"
                            onChange={(e) => handleInputChange(e.target.value, 'title')}
                          />
                          {errors.title && <p style={{ color: "red" }}>{errors.title}</p>}
                        </div>
                         
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">
                              Description<span style={{ color: "red" }}>*</span>
                            </label>
                            <ReactQuill
  className="about_us_page_edit_react_quill"
  id="description"
  name="description"
  onChange={(value) => handleInputChange(value, 'description')}
/>
{errors.description && <p style={{ color: "red" }}>{errors.description}</p>}

                          </div>
                            
                         
                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}  style={{
                            backgroundColor: "#891621",
                            borderColor: "#5f1e24",
                          }}>
          {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Submit'}
        </button>
        <Link to="/refundListPage">
        <button
    type="button"
    className="btn btn-primary"
    style={{
      backgroundColor: "#891621",
      borderColor: "#5f1e24",
    }}
>
    <span>Cancel</span>
</button>

</Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddblogModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
        </div>
      </div>
    </div>
  );
}

export default RefundCreate;
