import React, { useState } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload, DatePicker } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { addMainformData} from '../../Component/Controller/Main';
import Addmainpopup from "./Addmainpopup";
// import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Main.css";

function AddMainData() {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
    const [errors, setErrors] = useState('');
    const [popUpOpen, setPopUpOpen] = useState(false);
    const togglePopUp = () => {
      setPopUpOpen(!popUpOpen); 
    };
   // console.log(adminInformation);
    const [formData, setFormData] = useState({
      title: "",
      description: "",
      image_1: null,
      image_2: null,
      image_3: null,
      
    });
     /*************************************************************
 * Function Name  : handleInputChange
 * Created Date   : 10-01-2024
 *************************************************************/
     const handleFileChange1 = (e) => {
      const file = e.target.files[0];
      setFormData((prevFormData) => ({ ...prevFormData, image_1: file }));
    };
  
    const handleFileChange2 = (e) => {
      const file = e.target.files[0];
      setFormData((prevFormData) => ({ ...prevFormData, image_2: file }));
    };
    const handleFileChange3 = (e) => {
      const file = e.target.files[0];
      setFormData((prevFormData) => ({ ...prevFormData, image_3: file }));
    };
     const handleInputChange = (e, field) => {
      const { name, value, files } = e.target;
    
     
        setFormData((prevVal) => ({
          ...prevVal,
          [name]: value,
        }));
        setErrors((prevError) => ({
          ...prevError,
          [name]: '',
        }));
      
    };
    
    
    
    
 
  /*************************************************************
 * Function Name  : handleSubmit
 * Purposs        : This function is used add social icon data
 * Created Date   : 10-01-2024
 *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
       if (!formData.title) {
        setErrors((preError) => ({
          ...preError,
          title: 'Title is required.',
        }));
      } else if (!formData.description) {
        setErrors((preError) => ({
          ...preError,
          description: 'Detail is required.',
        }));
      }else{
        const updateResult = await addMainformData(formData, adminInformation.token);
        if(updateResult.status === 200){
            setFormData((prevFormData) => ({
                ...prevFormData,
               page_name: "",
              title: "",
              description: "",
              image_1: "",
              image_2: "",
              image_3: "",
            }));
          togglePopUp();
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
   /*************************************************************
          End Function
   *************************************************************/
  const { Dragger } = Upload;
 

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Add Main Page </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group">
                        <label htmlFor="fullname">Title</label>
                        <input
                          type="text"
                          className="form-control common_category_input_for_all_section"
                          id="title"
                          name="title"
                          onChange={handleInputChange}
                          placeholder="Enter Social Icon Link"
                        />
                         {errors?.title?(<p style={{color:"red"}}>{errors?.title}</p>):''}
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleTextarea">Details</label>
                        <textarea
                          className="form-control common_category_input_for_all_section"
                          id="description"
                          name="description"
                          onChange={handleInputChange}
                          rows="3"
                        ></textarea>
                           {errors?.description?(<p style={{color:"red"}}>{errors?.description}</p>):''}
                      </div>
                      <div className="form-group">
                        <label htmlFor="fullname">Image 1</label>
                        <input
                          type="file"
                          className="form-control common_category_input_for_all_section"
                          id="image_1"
                          name="image_1"
                          onChange={handleFileChange1}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="fullname">Image 2</label>
                          <input
                          type="file"
                          className="form-control common_category_input_for_all_section"
                          id="image_2"
                          name="image_2"
                          onChange={handleFileChange2}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="fullname">Image 3</label>
                          <input
                          type="file"
                          className="form-control common_category_input_for_all_section"
                          id="image_3"
                          name="image_3"
                          onChange={handleFileChange3}
                        />
                      </div>
                     <button type="submit" className="btn btn-primary"  style={{ backgroundColor: '#891621',borderColor:'#5f1e24' }}>
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <Addmainpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} /> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddMainData;
