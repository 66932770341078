import { RiEyeCloseFill } from 'react-icons/ri';
import  { API_ADMIN_URL }  from '../Config/constant';
import {getRequest, postRequest,deleteRequest} from './API';
/*********************************************************
 *  This function is use to getSocialDatafetch api
 *********************************************************/
export const getInTouchDatafetch = async (options , authToken)=>{
    try{
        const {page,category_name}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'getintouch/list-getintouch',
                postData : options,
                headers: {
                  authToken: authToken
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function
/*********************************************************
 *  This function is use to getBannerData api
 *********************************************************/

export const getintouchData = async (options , authToken) =>{
  try{
      const {id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'getintouch/get-getintouch',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};
 /*********************************************************
 *  This function is use to addsocialicondata api
 *********************************************************/
 export const addGetInTouchData = async (options, authToken) => {
    try {
      const { email,address,phone_no} = options;
      const posrData = {
        url: API_ADMIN_URL + 'getintouch/add-getintouch',
        postData: options,
        headers: {
          authToken: authToken
        }
      };
      const res = await postRequest(posrData);
      if (res) {
        return res;
      } else {
        return { status: false, message: res?.response?.data?.statusMessage };
      }
    } catch (error) {
      return { status: false, message: "Under Maintenance, Please try after some time." };
    }
  };
   // End of Function
   /*********************************************************
 *  This function is use to addsocialicondata api
 *********************************************************/
   export const updateGetInTouchformData = async (options, authToken) => {
    try {
      const { email,address, id,phone_no } = options;
      const postData = {
        url: API_ADMIN_URL + 'getintouch/edit-getintouch',
        postData: options,
        headers: {
          authToken: authToken,
        },
      };
      const res = await postRequest(postData);
  
      if (res && res.status === 200) {
        return res.data; // Assuming your response has a 'data' property
      } else {
        return { status: false, message: res?.data?.statusMessage || 'Request failed.' };
      }
    } catch (error) {
      console.error('Error updating banner:', error);
      return { status: false, message: 'Under Maintenance, Please try after some time.' };
    }
  };
  export const statusCategoryData = async (options, authToken) => {
    try {
      const { status,id } = options;
      const postData = {
        url: API_ADMIN_URL + 'getintouch/status',
        postData: options,
        headers: {
          authToken: authToken,
        },
      };
      const res = await postRequest(postData);
  
      if (res && res.status === 200) {
        return res.data; // Assuming your response has a 'data' property
      } else {
        return { status: false, message: res?.data?.statusMessage || 'Request failed.' };
      }
    } catch (error) {
      console.error('Error updating banner:', error);
      return { status: false, message: 'Under Maintenance, Please try after some time.' };
    }
  };
 // End of Function

  
/*********************************************************
 *  This function is use to deleteBannerData api
 *********************************************************/
export const deleteGetInTouchData = async (options ,authToken) => {
  try {
    const { id } = options;
    const postData = {
      id: id,
    };
    const postOptions = {
      url: API_ADMIN_URL + 'getintouch/delete-getintouch',
      postData: postData,
      headers: {
        authToken: authToken
      }
    };
    const res = await deleteRequest(postOptions);
    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};
