import { RiEyeCloseFill } from 'react-icons/ri';
import  { API_ADMIN_URL }  from '../Config/constant';
import {getRequest, postRequest,deleteRequest} from './API';
/*********************************************************
 *  This function is use to getSocialDatafetch api
 *********************************************************/
export const getCategoryDatafetch = async (options , authToken)=>{
    try{
        const {page,category_name}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'category/list-category',
                postData : options,
                headers: {
                  authToken: authToken
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function
export const getcategorytotalDatafetch = async (options , authToken)=>{
  try{
      const {}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'category/total-category',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
            
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function


/*********************************************************
 *  This function is use to getBannerData api
 *********************************************************/
export const getCategoryData = async (options , authToken) =>{
  try{
      const {id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'category/get-category',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function
 /*********************************************************
 *  This function is use to addsocialicondata api
 *********************************************************/
 export const addCategoryformData = async (options, authToken) => {
    try {
      const { category_name,show,image,color} = options;
      const formData = new FormData();
      formData.append('category_name', category_name);
      formData.append('show', show);
      formData.append('image', image);
      formData.append('color', color);
      const posrData = {
        url: API_ADMIN_URL + 'category/add-category',
        postData: formData,
        headers: {
          authToken: authToken
        }
      };
      const res = await postRequest(posrData);
      if (res) {
        return res;
      } else {
        return { status: false, message: res?.response?.data?.statusMessage };
      }
    } catch (error) {
      return { status: false, message: "Under Maintenance, Please try after some time." };
    }
  };
   // End of Function
   /*********************************************************
 *  This function is use to addsocialicondata api
 *********************************************************/
   export const updateCategoryformData = async (options, authToken) => {
    try {
      const { category_name, id,show,image,color } = options;
      const formData = new FormData();
      formData.append('id', id);
      formData.append('category_name', category_name);
      formData.append('show', show);
      formData.append('image', image);
      formData.append('color', color);
      const postData = {
        url: API_ADMIN_URL + 'category/edit-category',
        postData: formData,
        headers: {
          authToken: authToken,
        },
      };
      const res = await postRequest(postData);
  
      if (res && res.status === 200) {
        return res.data; // Assuming your response has a 'data' property
      } else {
        return { status: false, message: res?.data?.statusMessage || 'Request failed.' };
      }
    } catch (error) {
      console.error('Error updating banner:', error);
      return { status: false, message: 'Under Maintenance, Please try after some time.' };
    }
  };
  export const statusCategoryData = async (options, authToken) => {
    try {
      const { status, id} = options;
      const postData = {
        url: API_ADMIN_URL + 'category/status',
        postData: options,
        headers: {
          authToken: authToken,
        },
      };
      const res = await postRequest(postData);
  
      if (res && res.status === 200) {
        return res.data; // Assuming your response has a 'data' property
      } else {
        return { status: false, message: res?.data?.statusMessage || 'Request failed.' };
      }
    } catch (error) {
      console.error('Error updating banner:', error);
      return { status: false, message: 'Under Maintenance, Please try after some time.' };
    }
  };
 // End of Function

  
/*********************************************************
 *  This function is use to deleteBannerData api
 *********************************************************/
export const deleteCategoryData = async (options ,authToken) => {
  try {
    const { id } = options;
    const postData = {
      id: id,
    };
    const postOptions = {
      url: API_ADMIN_URL + 'category/delete-category',
      postData: postData,
      headers: {
        authToken: authToken
      }
    };
    const res = await deleteRequest(postOptions);
    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};
