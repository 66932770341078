import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { IoMdClose } from "react-icons/io";
import "../Contact Us/ContactUs.css";
import OrderPopUp from "./OrderPopUp";
import { getOrderShipping, getOrderShippingcreate,shippingOrderData } from "../Controller/Order";

const OrderShippingPopUp = ({
  popUpOpenshipping,
  handleViewShipping,
  itemIdPassShipping,
}) => {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [height, setHeight] = useState("");
  const [breadth, setBreadth] = useState("");
  const [weight, setWeight] = useState("");
  const [length, setLength] = useState("");
  const [comment, setComment] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);

  const handleCancel = () => {
    handleViewShipping();
    setHeight("");
    setBreadth("");
    setWeight("");
    setLength("");
    setComment("");
  };

  const handleDelete = async () => {
    const options = {
      order_id: itemIdPassShipping?.order_id,
      order_detail_id: itemIdPassShipping?._id,
      user_id: itemIdPassShipping?.user_id,
      channel_id: "853859",
      order_date: itemIdPassShipping?.created_date || new Date().toISOString(),
      height,
      breadth,
      weight,
      length,
    };
    try {
      console.log(options);
      const result = await getOrderShipping(options, adminInformation.token);
         console.log(result);
      if (result) {
        const orderItems = itemIdPassShipping?.cartItems.flatMap((product) => 
            product.variants.map((variant) => ({
              name: product.product_id.product_name,
              sku: variant._id,
              units: variant.quantity,
              selling_price: variant.price,
              hsn: 441122,
            }))
          );
          
        const optionsorder = {
          order_id: itemIdPassShipping?.order_id,
          order_date: itemIdPassShipping?.created_date,
          pickup_location: "Primary",
          channel_id: "853859",
          billing_customer_name: itemIdPassShipping.user_id?.fullName,
          billing_last_name: "",
          billing_address: itemIdPassShipping.address_id?.land_mark,
          billing_city: itemIdPassShipping.address_id?.city,
          billing_pincode: itemIdPassShipping.address_id?.postal_code,
          billing_state: itemIdPassShipping.address_id?.state,
          billing_country: itemIdPassShipping.address_id?.country,
          billing_email: itemIdPassShipping.user_id?.email,
          billing_phone: itemIdPassShipping.user_id?.mobile,
          shipping_is_billing: true,
          order_items: orderItems,
          payment_method: itemIdPassShipping?.payment_mode,
          shipping_charges: 0,
          giftwrap_charges: 0,
          transaction_charges: 0,
          total_discount: 0,
          sub_total: itemIdPassShipping?.total_price,
          height,
          breadth,
          weight,
          length,
          auth: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjQ2ODg0ODYsInNvdXJjZSI6InNyLWF1dGgtaW50IiwiZXhwIjoxNzE4NDM1MTg5LCJqdGkiOiJ6eEVtdGliTkRHZWJJSjdEIiwiaWF0IjoxNzE3NTcxMTg5LCJpc3MiOiJodHRwczovL3NyLWF1dGguc2hpcHJvY2tldC5pbi9hdXRob3JpemUvdXNlciIsIm5iZiI6MTcxNzU3MTE4OSwiY2lkIjo2OTY0NTgsInRjIjozNjAsInZlcmJvc2UiOmZhbHNlLCJ2ZW5kb3JfaWQiOjAsInZlbmRvcl9jb2RlIjoiIn0.uP81SjTf5_k_ZxQIoY2satGJ95izKODvClEl0zp3qFo"
        };
        console.log("optionsorder",optionsorder);
        const result1 = await shippingOrderData(optionsorder);
        console.log("result1",result1);
        if (result1) {
          const options22 = {
            shipment_order_id: result1.data.result.order_id,
            shipment_id: result1.data.result.shipment_id,
            order_id: itemIdPassShipping?.order_id,
          };
            try {
              const resultorder = await getOrderShippingcreate(
                options22,
                adminInformation.token
              );
              if (resultorder) {
                setPopUpOpen(true); // Open OrderPopUp on successful order creation
                handleViewShipping();
                setHeight("");
                setBreadth("");
                setWeight("");
                setLength("");
                setComment("");
              }
            } catch (error) {
              console.error("Error creating order:", error);
            }
          } else {
            console.error("Error creating order:", result1);
          }
       
      } else {
        console.error("Unexpected response format:", result);
      }
    } catch (error) {
      console.error("Error deleting social icon data:", error);
    }
  };

  return (
    <>
      <Dialog
        open={popUpOpenshipping}
        onClose={handleViewShipping}
        maxWidth="md"
        PaperProps={{
          className: "myDialogPopUp",
        }}
      >
        <div className="myDrawer">
          <div className="myMainDrawerClass">
            <div>
              <Typography
                variant="h5"
                component="div"
                gutterBottom
                className="myFilterClass"
              >
                Shipping Detail
              </Typography>
            </div>
            <div>
              <IconButton onClick={handleViewShipping}>
                <IoMdClose />
              </IconButton>
            </div>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            {/* <p>Do you really want to accept the order?</p> */}
          </Typography>
          {/* Input fields for height, breadth, weight, length, and comment */}
          <div className="order_shipping_popup_main_section_for_height_">
            <TextField
              label="Height"
              variant="outlined"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Breadth"
              variant="outlined"
              value={breadth}
              onChange={(e) => setBreadth(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Weight"
              variant="outlined"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Length"
              variant="outlined"
              value={length}
              onChange={(e) => setLength(e.target.value)}
              fullWidth
              margin="normal"
            />
            {/* <TextField
            label="Comment"
            variant="outlined"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            fullWidth
            margin="normal"
          /> */}
          </div>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDelete} color="primary">
              Ok
            </Button>
          </DialogActions>
        </div>
      </Dialog>

        <OrderPopUp
          popUpOpen={popUpOpen}
          togglePopUp={() => setPopUpOpen(false)}
        />
    </>
  );
};

export default OrderShippingPopUp;
