import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";

import { useLocation ,Link} from "react-router-dom";
import { imageURL } from "../Config/constant";

function BulkOrderDetail() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const location = useLocation();
  const selectedItem = location.state.selectedItem;
  const selectedItemvariant = location.state.selectedItem.products;
  const [responseData, setResponseData] = useState(null); // Define responseData state

  function formatDate(timestamp) {
    // Convert timestamp to milliseconds by removing the last 2 characters (milliseconds)
    const milliseconds = Number(String(timestamp).slice(0, -2));
  
    // Create a Date object from the milliseconds
    const date = new Date(milliseconds);
  
    // Define options for formatting
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };
  
    // Return the formatted date string
    return date.toLocaleDateString(undefined, options);
  }
const formdatacreate= formatDate(selectedItem.createdAt);
 // Function to capitalize the first letter
 const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <div>
                    {/* <h5>Product Detail</h5> */}
                  </div>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <div
                      className="my_add_event_form_field"
                      style={{ float: "right" }}
                    >
                      <Link to="/bulkOrderList">
                        <button type="button" className="btn btn-primary"  style={{ backgroundColor: "#891621", borderColor: "#5f1e24" }}>
                          Back
                        </button>
                      </Link>
                    </div>
                    <div className="responsive">
                      <div>
                        <div className="form-group">
                          <label htmlFor="exampleInputText">
                            <h3>Seller Detail :- </h3>
                          </label>
                          <p>Name :- {capitalizeFirstLetter(selectedItem.sellerName)}</p>
                          <p>Email :- {selectedItem.email}</p>
                          <p>Shop Name: {capitalizeFirstLetter(selectedItem.sellerShop)}</p>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputText">
                            Order Details:
                          </label>
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">Product Image</th>
                                <th scope="col">Product Name</th>
                                <th scope="col">Product Volume</th>
                                <th scope="col">Price</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Order Date</th>
                              </tr>
                            </thead>
                            <tbody>
                            {selectedItem.products && selectedItem.products.map((orderDetail, index) => {
  let matchingVariant = null;

  if (selectedItemvariant) {
    matchingVariant = selectedItemvariant.find(item => {
      const foundVariant = item.products_id.variants && item.products_id.variants.find(variant => variant._id === orderDetail.variants_id);
      return foundVariant !== undefined;
    });

    // If matchingVariant is found, update its images
    if (matchingVariant) {
      const foundVariant = matchingVariant.products_id.variants.find(variant => variant._id === orderDetail.variants_id);
      matchingVariant = { ...matchingVariant, images: foundVariant ? foundVariant.images : [], volume: foundVariant ? foundVariant.volume : "" };
    }
  }


 
  return (
    <tr key={index}>
      <td>
        <div>
          {matchingVariant && matchingVariant.images && matchingVariant.images.length > 0 && (
            <img
              src={`${imageURL}${matchingVariant.images[0]}`}
              alt={orderDetail.products_id.product_name}
              style={{ width: "100px", height: "100px" }}
            />
          )}
        </div>
      </td>
      <td>{capitalizeFirstLetter(orderDetail.products_id.product_name)}</td> {/* Display product name with first letter capitalized */}
      <td>{matchingVariant ? matchingVariant.volume : ""}</td> {/* Display volume here */}
      <td>{orderDetail.total}</td>
      <td>{orderDetail.quantity}</td>
      <td>{formdatacreate}</td> 
    </tr>
  );
})}

</tbody>





                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BulkOrderDetail;
