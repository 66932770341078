import React from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import "../AllCssFile.css";
import { FaDesktop } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";

function SalesOrderEdit() {
  const { Dragger } = Upload;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <div>
                    <h5>Sales Order Edit</h5>
                  </div>
                  <Link to="/aboutUsShowingpage">
                    <div>
                      <button
                        type="button"
                        className="btn btn-light about_us_showing_page_edit_button"
                      >
                        <FaDesktop /> Show
                      </button>
                    </div>
                  </Link>
                </div>

                <div className="card shadow mb-4">
                  <div className="card-header py-3 dataBase_table_Add_Data">
                    <div>
                      <h6 className="m-0 font-weight-bold text-primary">
                        Title
                      </h6>
                      <h6 className="m-0 font-weight-bold text-primary">
                        Story
                      </h6>
                    </div>
                    <div>
                      <button type="button" className="btn btn-primary">
                        Delete Data
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="responsive">
                      <form action="">
                        <form>
                          <div className="form-group">
                            <label htmlFor="exampleInputText">Title</label>
                            <input
                              type="text"
                              className="form-control common_category_input_for_all_section"
                              id="exampleInputText"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">
                              Description
                            </label>
                            <ReactQuill className="about_us_page_edit_react_quill" />
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">
                              Images
                            </label>
                            <Dragger className="about_us_dragger_file">
                              <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                              </p>
                              <p className="ant-upload-text">
                                Click or drag file to this area to upload
                              </p>
                              <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly
                                prohibited from uploading company data or other
                                banned files.
                              </p>
                            </Dragger>
                            <div className="about_us_page_submit_btn">
                              <button
                                type="button"
                                className="btn btn-primary about_us_page_save_button"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SalesOrderEdit;
