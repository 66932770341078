import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload } from "antd";
import "../AllCssFile.css";
import ReactQuill from "react-quill";
import { imageURL } from "../Config/constant";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import {
  updateProductformData,
  getProductdata,
  getCategoryDatafetch,
} from "../Controller/Product";
import { useParams } from "react-router-dom";
import AddProductpopup from "./Editproducypopup";
function EditProductData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [categories, setCategoryData] = useState([]);
  const { edit } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [imageList, setImageList] = useState([""]); // Initialize with an empty string

  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [formData, setFormData] = useState({
    product_name: "",
    category: "",
    ingredients: "",
    recipes: "",
    page_show: "",
    page_show_bottom: "",
    sku: "",
    hsn: "",
    gst:"",
    description: "",
    variants: [
      {
        volume: "",
        price: "",
        sellingprice: "",
        product_stock: "",
        show: false,
        images: [],
      },
    ],
  });

  const handleInputChange = (value, field, index) => {
    if (field === "category") {
      const [categoryId, categoryName] = value.split("|");
      setFormData((prevVal) => ({
        ...prevVal,
        category: categoryId,
        category_name: categoryName,
      }));
    } else if (
      field === "description" ||
      field === "ingredients" ||
      field === "recipes"
    ) {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: value,
      }));
    } else if (field === "page_show" || field === "page_show_bottom") {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: value, // value is already a boolean, so no need for ternary operation
      }));
    } else if (field.startsWith("variant_")) {
      const variantIndex = parseInt(field.split("_")[1], 10);
      const variantKey = field.split("_")[2];
      setFormData((prevVal) => {
        const updatedVariants = [...prevVal.variants];
        updatedVariants[variantIndex][variantKey] = value;
        return { ...prevVal, variants: updatedVariants };
      });
    } else {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: value,
      }));
      setErrors((prevError) => ({
        ...prevError,
        [field]: "",
      }));
    }
  };
  /*************************************************************
   * Function Name  : handleSubmit
   * Purposs        : This function is used add social icon data
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.product_name) {
        setErrors((preError) => ({
          ...preError,
          product_name: "Product name is required.",
        }));
      } else if (!formData.sku) {
        setErrors((preError) => ({
          ...preError,
          sku: "SKU No is required.",
        }));
      } else if (!formData.hsn) {
        setErrors((preError) => ({
          ...preError,
          hsn: "HSN no is required.",
        }));
      }  else if (!formData.gst) {
        setErrors((preError) => ({
          ...preError,
          gst: "Gst is required.",
        }));
      }else if (!formData.category) {
        setErrors((preError) => ({
          ...preError,
          category: "Category is required.",
        }));
      } else if (!formData.description) {
        setErrors((preError) => ({
          ...preError,
          description: "Description is required.",
        }));
      } else if (!formData.ingredients) {
        setErrors((preError) => ({
          ...preError,
          ingredients: "Ingredients is required.",
        }));
      } else if (!formData.recipes) {
        setErrors((preError) => ({
          ...preError,
          recipes: "Recipes is required.",
        }));
      } else {
        setIsSubmitting(true);
        const updateResult = await updateProductformData(
          formData,
          adminInformation.token,
          formData.variants,
          imageList
        );
        if (updateResult.status === true) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            product_name: "",
            category: "",
            ingredients: "",
            recipes: "",
            price: "",
            page_show: "",
            page_show_main: "",
            quantity: "",
            product_stoke: "",
            description: "",
            product_image: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  /*************************************************************
          End Function
   *************************************************************/
  /*************************************************************
   * Function Name  : getblogdata
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getproductfetchdata = async () => {
    const options = { id: edit };
    try {
      const result = await getProductdata(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result[0];
        setFormData({
          ...responseData,
          id: responseData._id, // Set the id property
        });
        setImageList(responseData.product_image || [""]);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const fetchCategories = async () => {
    const options = {};
    try {
      const result = await getCategoryDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setCategoryData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const handleFileChangeForRow = (e, index) => {
    const files = e.target.files;
    const updatedVariants = [...formData.variants];

    // Remove existing images for the corresponding variant index
    updatedVariants[index].images = [];

    // Update the images array for the corresponding variant index
    updatedVariants[index].images = [...files];

    // Update the form data with the new images
    setFormData((prevFormData) => ({
      ...prevFormData,
      variants: updatedVariants,
    }));
  };
  const addVariant = () => {
    setFormData((prevVal) => ({
      ...prevVal,
      variants: [
        ...prevVal.variants,
        {
          volume: "",
          price: "",
          sellingprice: "",
          productstock: "",
          show: false,
          images: [],
        },
      ],
    }));
  };

  const removeVariant = (index) => {
    setFormData((prevVal) => {
      const updatedVariants = [...prevVal.variants];
      updatedVariants.splice(index, 1);
      return { ...prevVal, variants: updatedVariants };
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getproductfetchdata();
    fetchCategories();
  }, []);
  const { Dragger } = Upload;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <div>
                    <h5>Edit Product Content</h5>
                  </div>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <div className="responsive">
                      <form
                        onSubmit={handleSubmit}
                        encType="multipart/form-data"
                      >
                        <div className="form-group row">
                          <div className="col-md-6">
                            <label htmlFor="exampleInputText">
                              Product name
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control common_category_input_for_all_section"
                              id="product_name"
                              name="product_name"
                              value={formData.product_name}
                              onChange={(e) =>
                                handleInputChange(
                                  e.target.value,
                                  "product_name"
                                )
                              }
                            />
                            {errors?.product_name ? (
                              <p style={{ color: "red" }}>
                                {errors?.product_name}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="exampleInputText">
                              SKU no<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control common_category_input_for_all_section"
                              id="sku"
                              name="sku"
                              value={formData.sku}
                              onChange={(e) =>
                                handleInputChange(e.target.value, "sku")
                              }
                            />
                            {errors?.sku ? (
                              <p style={{ color: "red" }}>{errors?.sku}</p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="exampleInputText">
                              HSN No<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control common_category_input_for_all_section"
                              id="hsn"
                              name="hsn"
                              value={formData.hsn}
                              onChange={(e) =>
                                handleInputChange(e.target.value, "hsn")
                              }
                            />
                            {errors?.hsn ? (
                              <p style={{ color: "red" }}>{errors?.hsn}</p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="exampleInputText">
                              Gst<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control common_category_input_for_all_section " value={formData.gst}
                              onChange={(e) =>
                                handleInputChange(e.target.value, "gst")
                              }
                            >
                              <option value="">Select Gst</option>
                              <option value="8 %">8 %</option>
                              <option value="12 %">12 %</option>
                              <option value="18 %">18 %</option>
                            </select>
                            {errors?.gst ? (
                              <p style={{ color: "red" }}>{errors?.gst}</p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="exampleInputText">
                              Category<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control common_category_input_for_all_section"
                              onChange={(e) =>
                                handleInputChange(e.target.value, "category")
                              }
                            >
                              <option value="">Select Category</option>
                              {categories.map((category) => (
                                <option
                                  key={category._id}
                                  value={`${category._id}|${category.category_name}`}
                                  selected={category._id === formData.category} // Set selected based on the current formData
                                >
                                  {category.category_name}
                                </option>
                              ))}
                            </select>

                            {errors?.category ? (
                              <p style={{ color: "red" }}>{errors?.category}</p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Description<span style={{ color: "red" }}>*</span>
                          </label>
                          <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={(value) =>
                              handleInputChange(value, "description")
                            }
                          />
                          {errors?.description ? (
                            <p style={{ color: "red" }}>
                              {errors?.description}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Ingredients<span style={{ color: "red" }}>*</span>
                          </label>
                          <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="ingredients"
                            name="ingredients"
                            value={formData.ingredients}
                            onChange={(value) =>
                              handleInputChange(value, "ingredients")
                            }
                          />
                          {errors?.ingredients ? (
                            <p style={{ color: "red" }}>
                              {errors?.ingredients}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                          Recipes<span style={{ color: "red" }}>*</span>
                          </label>
                          <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="recipes"
                            name="recipes"
                            value={formData.recipes}
                            onChange={(value) =>
                              handleInputChange(value, "recipes")
                            }
                          />
                          {errors?.recipes ? (
                            <p style={{ color: "red" }}>{errors?.recipes}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="form-group">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Variant</th>
                                <th>MRP Price</th>
                                <th>Selling Price</th>
                                <th>Product Stock</th>
                                <th>Active</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {formData.variants.map((variant, index) => (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control common_category_input_for_all_section"
                                        id={`volume${index}`}
                                        name={`volume${index}`}
                                        value={variant.volume}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e.target.value,
                                            `variant_${index}_volume`,
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control common_category_input_for_all_section"
                                        id={`price${index}`}
                                        name={`price${index}`}
                                        value={variant.price}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e.target.value,
                                            `variant_${index}_price`,
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control common_category_input_for_all_section"
                                        id={`sellingprice${index}`}
                                        name={`sellingprice${index}`}
                                        value={variant.sellingprice}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e.target.value,
                                            `variant_${index}_sellingprice`,
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        className="form-control common_category_input_for_all_section"
                                        id={`productstock${index}`}
                                        name={`productstock${index}`}
                                        value={variant.productstock}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e.target.value,
                                            `variant_${index}_productstock`,
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        className="form-check"
                                        id={`show_${index}`}
                                        name={`show_${index}`}
                                        checked={variant.show}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e.target.checked,
                                            `variant_${index}_show`,
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      {index > 0 && (
                                        <button
                                          type="button"
                                          className="btn btn-danger remove-variant"
                                          onClick={() => removeVariant(index)}
                                        >
                                          Remove
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="8">
                                      <div className="d-flex flex-row">
                                        {/* Render input fields for images */}
                                        <input
                                          type="file"
                                          accept="image/*"
                                          multiple
                                          onChange={(e) =>
                                            handleFileChangeForRow(e, index)
                                          }
                                        />
                                        {/* Display images */}
                                        {variant.images.map(
                                          (file, imageIndex) => (
                                            <div
                                              key={imageIndex}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <img
                                                src={
                                                  typeof file === "string"
                                                    ? `${imageURL}${file}`
                                                    : URL.createObjectURL(file)
                                                }
                                                alt={`Image ${imageIndex}`}
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                  margin: "5px",
                                                }}
                                              />
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>
                          <br />
                          <div className="col-md-2 product_add_button_varient">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={addVariant}
                              style={{
                                backgroundColor: "#891621",
                                borderColor: "#5f1e24",
                              }}
                            >
                              Add
                            </button>
                          </div>
                        </div>

                        <div className="form-group">
                          <label htmlFor="exampleInputCheckbox">
                            Page Show
                          </label>
                          <input
                            type="checkbox"
                            style={{
                              marginLeft: "14px",
                              borderColor: "#df1616",
                            }}
                            className="form-check-input"
                            id="page_show"
                            name="page_show"
                            checked={formData.page_show === "Yes"}
                            onChange={(e) =>
                              handleInputChange(
                                e.target.checked ? "Yes" : "No",
                                "page_show"
                              )
                            }
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputCheckbox">
                            Page Show Bottom
                          </label>
                          <input
                            type="checkbox"
                            style={{
                              marginLeft: "14px",
                              borderColor: "#df1616",
                            }}
                            className="form-check-input"
                            id="page_show_bottom"
                            name="page_show_bottom"
                            checked={formData.page_show_bottom === "Yes"}
                            onChange={(e) =>
                              handleInputChange(
                                e.target.checked ? "Yes" : "No",
                                "page_show_bottom"
                              )
                            }
                          />
                        </div>
                        <div className="misrambhu_total_btn_for_gap">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isSubmitting}
                            style={{
                              backgroundColor: "#891621",
                              borderColor: "#5f1e24",
                            }}
                          >
                            {isSubmitting ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              "Update"
                            )}
                          </button>
                          <Link to="/productListPage">
                            <button
                              type="button"
                              className="btn btn-primary"
                              style={{
                                backgroundColor: "#891621",
                                borderColor: "#5f1e24",
                              }}
                            >
                              <span>Cancel</span>
                            </button>
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddProductpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
        </div>
      </div>
    </div>
  );
}
export default EditProductData;
