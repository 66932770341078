import { RiEyeCloseFill } from 'react-icons/ri';
import  { API_ADMIN_URL }  from '../Config/constant';
import {getRequest, postRequest,deleteRequest} from '../Controller/API';
/*********************************************************
 *  This function is use to getMainDatafetch api
 *********************************************************/
export const getMainDatafetch = async (options , authToken)=>{
    try{
        const {page,title}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'main/list-main',
                postData : options,
                headers: {
                  authToken: authToken
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function
/*********************************************************
 *  This function is use to getMainData api
 *********************************************************/
export const getMainData = async (options , authToken) =>{
  try{
      const {id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'main/get-main',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function
 /*********************************************************
 *  This function is use to addMainformData api
 *********************************************************/
 export const addMainformData = async (options, authToken) => {
    try {
      const { title, description, image_1,image_2,image_3 } = options;
  
      // Create a FormData object
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      formData.append('image_3', image_3);
      formData.append('image_1', image_1);
      formData.append('image_2', image_2);
  
      
      const posrData = {
        url: API_ADMIN_URL + 'main/add-main',
        postData: formData,
        headers: {
          authToken: authToken,
          'Content-Type': 'multipart/form-data',
        },
      };
  
      console.log(posrData);
  
      const res = await postRequest(posrData);
      console.log(res);
  
      if (res) {
        return res;
      } else {
        return { status: false, message: res?.response?.data?.statusMessage };
      }
    } catch (error) {
      return { status: false, message: "Under Maintenance, Please try after some time." };
    }
  };
   // End of Function
   /*********************************************************
 *  This function is use to updateMainformData api
 *********************************************************/
   export const updateMainformData = async (options, authToken) => {
    try {
      const { title, description, image_1, image_2,image_3 ,id } = options;
      const postData = {
        url: API_ADMIN_URL + 'main/edit-main',
        postData: options,
        headers: {
          authToken: authToken,
          'Content-Type': 'multipart/form-data',
        },
      };
      const res = await postRequest(postData);
  
      if (res && res.status === 200) {
        return res.data; // Assuming your response has a 'data' property
      } else {
        return { status: false, message: res?.data?.statusMessage || 'Request failed.' };
      }
    } catch (error) {
      console.error('Error updating banner:', error);
      return { status: false, message: 'Under Maintenance, Please try after some time.' };
    }
  };
  
 // End of Function

  
/*********************************************************
 *  This function is use to deleteMainData api
 *********************************************************/
export const deleteMainData = async (options ,authToken) => {
  try {
    const { id } = options;
    const postData = {
      id: id,
    };
    const postOptions = {
      url: API_ADMIN_URL + 'main/delete-main',
      postData: postData,
      headers: {
        authToken: authToken
      }
    };
    const res = await deleteRequest(postOptions);
    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};
