import React, { useState, useEffect } from "react";
import SideBar from "../../SideBar/SideBar";
import Header from "../../HeaderDashBoard/Header";
import { DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";
import { addSocialIconData } from "../../Controller/SocialIcon";
import AddSocialIconModel from "./AddSocialIconModel";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./SocialiconData.css";

function SocialIconFormCreate() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  // console.log(adminInformation);
  const [formData, setFormData] = useState({
    icon_name: "",
    icon_link: "",
  });
  /*************************************************************
   * Function Name  : handleInputChange
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((preVal) => ({
      ...preVal,
      [name]: value,
    }));
    setErrors((preError) => ({
      ...preError,
      [name]: "",
    }));
  };

  /*************************************************************
   * Function Name  : handleSubmit
   * Purposs        : This function is used add social icon data
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.icon_name) {
        setErrors((preError) => ({
          ...preError,
          icon_name: "Icon name is required.",
        }));
      } else if (!formData.icon_link) {
        setErrors((preError) => ({
          ...preError,
          icon_link: "Icon link is required.",
        }));
      } else {
        setIsSubmitting(true);
        const updateResult = await addSocialIconData(
          formData,
          adminInformation.token
        );

        if (updateResult.status === 200) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            icon_name: "",
            icon_link: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  /*************************************************************
          End Function
   *************************************************************/

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { Search } = Input;

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Add Social Icon </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="fullname">
                          Social Icon Name
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control common_category_input_for_all_section"
                          id="icon_name"
                          name="icon_name"
                          placeholder="Enter Social Icon Name"
                          onChange={handleInputChange}
                        />
                        {errors?.icon_name ? (
                          <p style={{ color: "red" }}>{errors?.icon_name}</p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="fullname">
                          Social Icon Link
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control common_category_input_for_all_section"
                          id="icon_link"
                          name="icon_link"
                          onChange={handleInputChange}
                          placeholder="Enter Social Icon Link"
                        />
                        {errors?.icon_link ? (
                          <p style={{ color: "red" }}>{errors?.icon_link}</p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="misrambhu_total_btn_for_gap">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                          style={{
                            backgroundColor: "#891621",
                            borderColor: "#5f1e24",
                          }}
                        >
                          {isSubmitting ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Submit"
                          )}
                        </button>
                        <Link to="/socialIconList">
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{
                              backgroundColor: "#891621",
                              borderColor: "#5f1e24",
                            }}
                          >
                            <span>Cancel</span>
                          </button>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddSocialIconModel
              popUpOpen={popUpOpen}
              togglePopUp={togglePopUp}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialIconFormCreate;
