import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload, DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Form } from "react-bootstrap";
import { addCoupenformData, getCoupenDatafetch } from "../Controller/Coupen";
import AddCoupenpopup from "./AddCoupenpopup";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Coupen.css";
import { Link, useNavigate } from "react-router-dom";
import { FaRegCommentDots } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import Pagination from "@mui/material/Pagination";
import { MdDelete } from "react-icons/md";
import CoupenDelete from "./CoupenDelete";
import CategoryChange from "./statusChange";
function ListCoupenData() {
  // Delete Popup
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // add this line
  const [totalItems, setTotalItems] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const [popUpdeOpen, setPopUpdeOpen] = useState(false);
  const [coupenList, setCoupenData] = useState([]);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [itemIdToChange, setItemIdTochange] = useState(null);
  const [itemChange, setstatuschange] = useState(null);
  const [popUpOpenchange, setPopUpOpenchange] = useState(false);

  const togglePopUp = (itemId) => {
    setPopUpdeOpen(!popUpdeOpen);
    setItemIdToDelete(itemId);
    getcoupendata();
  };
  /*************************************************************
   * Function Name  : getcoupendata
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getcoupendata = async (page, coupon_code) => {
    const options = {
      page: page,
      coupon_code: coupon_code,
    };

    try {
      const result = await getCoupenDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result.coupen;
        const totalcount = result.data.response.result.totalcount;
        setCoupenData(responseData);
        setTotalItems(totalcount);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const handleRowClick = (itemId) => {
    navigate(`/editCoupenData/${itemId}`);
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    getcoupendata(page);
  };
  const togglePopUpchange = (itemId, status) => {
    setPopUpOpenchange(!popUpOpenchange);
    setItemIdTochange(itemId);
    setstatuschange(status === "A" ? "I" : "A");
    getcoupendata();
  };
  const handleSearch = (value) => {
    setSearchValue(value);
    getcoupendata(currentPage, value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getcoupendata(currentPage, searchValue);
  }, [currentPage, searchValue]);
  const dropdownMenu = (itemId, status) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleRowClick(itemId)}>
        <FaEdit />
        <span className="show_span_edit">Edit</span>
      </Menu.Item>
      <Menu.Item key="3" onClick={() => togglePopUpchange(itemId, status)}>
        <FaEdit />
        <span className="show_span_edit">
          {status === "A" ? "Inactive" : "Active"}
        </span>
      </Menu.Item>
      <Menu.Item key="3" onClick={() => togglePopUp(itemId)}>
        <MdDelete />
        <span className="show_span_edit">Delete</span>
      </Menu.Item>
    </Menu>
  );

  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Coupons List</h1>
                <p className="mb-4"></p>
                <div className="table_head_main_class">
                  <div className="my_antd_date_picker1">
                    
                  </div>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-header py-3 dataBase_table_Add_Data">
                    <h6
                      className="m-0 font-weight-bold"
                      style={{ color: "#891621", fontWeight: "bold" }}
                    >
                      Coupons Table List
                    </h6>
                    <div className="my_add_event_form_field">
                      <Link to="/createCoupenData">
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{
                            backgroundColor: "#891621",
                            borderColor: "#5f1e24",
                          }}
                        >
                          Add Coupon
                        </button>
                      </Link>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>
                              
                              S No
                            </th>
                            <th scope="col">Coupen Code</th>
                            <th scope="col">Discount</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {coupenList.length > 0 ? (
                            coupenList.map((item, index) => (
                              <tr key={index}>
                                <td>

                                  {(currentPage - 1) * 10 + index + 1}
                                </td>
                                <td>{item.coupon_code}</td>
                                <td>{item.discount}</td>
                                <td
                                  style={{
                                    color:
                                      item.status === "A" ? "green" : "red",
                                  }}
                                >
                                  {item.status === "A" ? "Active" : "Inactive"}
                                </td>
                                <td>
                                  <Dropdown
                                    overlay={() =>
                                      dropdownMenu(item._id, item.status)
                                    }
                                    placement="bottomLeft"
                                    arrow
                                  >
                                    {/* <FaRegCommentDots /> */}
                                    <BsThreeDots />
                                  </Dropdown>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4">No data found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="table_pagination_sales_order">
                        <Pagination
                          count={Math.ceil(totalItems / 10)}
                          page={currentPage}
                          onChange={(event, page) =>
                            handlePageChange(event, page)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CoupenDelete
        popUpdeOpen={popUpdeOpen}
        togglePopUp={togglePopUp}
        itemIdToDelete={itemIdToDelete}
      ></CoupenDelete>
      <CategoryChange
        popUpOpenchange={popUpOpenchange}
        togglePopUpchange={togglePopUpchange}
        itemIdToChange={itemIdToChange}
        status={itemChange} // Assuming status is available in your data
      />
    </div>
  );
}
export default ListCoupenData;
