import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Dashboard from "./Component/DashBoardMain/DashBaord";
import Login from "./Component/LoginPage/Login";
import Forgotpage from "./Component/ForgotPage/Forgotpage";
import ErrorPage from "./Component/404Page/ErrorPage";
import ConatctUsTable from "./Component/Contact Us/ConatctUsTable";
import ContatcUsAddressTable from "./Component/Contact Us/ContatcUsAddressTable";
import ContatcUsEnquiryTable from "./Component/Contact Us/ContactUsEnquiry";
import FaqList from "./Component/FAQ Pages/FaqList";
import FaqPageList from "./Component/FAQ Pages/FAQPageList";
import SalesOrderEdit from "./Component/Sales And Order/SalesOrderEdit";
import HomePage from "./Component/Home Page/HomePage";
import CreateCategories from "./Component/Blog Page/CreateCategories";
import PRODUCTBULKDATA from "./Component/Product/ProductBulkCreate";
import EDITPROFILE from "./Component/LoginPage/EditProfile";
import UtlityAnimation from "./Component/Utlities Page/UtilityAnimation";
import UtlityOther from "./Component/Utlities Page/UtilityOther";

// Routes
import SOCIALICON from "./Component/GeneralData/SocialIcon/SocialIcon";
import SOCIALICONCREATEFORM from "./Component/GeneralData/SocialIcon/SocialIconFormCreate";
import SOCIALICONEDITFORM from "./Component/GeneralData/SocialIcon/SocialiconEdit";
import NewsletterList from "./Component/GeneralData/Newsletter/newsletterList";
import GETINTOUCH from "./Component/GeneralData/GetInTouch/GetInTouch";
import GETINTOUCHEDITFORM from "./Component/GeneralData/GetInTouch/GetInTouchEdit";
import CREATEGETINTOUCH from "./Component/GeneralData/GetInTouch/GetInTouchFormCreate";

import BANNER from "./Component/Cms/Banner/ListBannerData";
import CREATEBANNER from "./Component/Cms/Banner/AddBannerData";
import EDITBANNERDATA from "./Component/Cms/Banner/EditBannerData";
import CATEGORY from "./Component/Category/ListCategoryData";
import CREATECATEGORY from "./Component/Category/AddCategoryData";
import EDITCATEGORY from "./Component/Category/EditCategoryData";
import BlogCREATEPage from "./Component/Blog Page/BlogCreate";
import BLogListPage from "./Component/Blog Page/ListBlog";
import BlogEditPage from "./Component/Blog Page/EditBlogData";


import TrickerCREATEPage from "./Component/Tricker/TrickerCreate";
import TrickerListPage from "./Component/Tricker/ListTricker";
import TrickerEditPage from "./Component/Tricker/EditTrickerData";  
import FAQCREATEPage from "./Component/Cms/faq/FaqCreate";
import FAQListPage from "./Component/Cms/faq/ListFaq";
import FAQEditPage from "./Component/Cms/faq/EditFaqData";
import PRODUCTCREATEPage from "./Component/Product/ProductCreate";
import PRODUCTListPage from "./Component/Product/ListProduct";
import PRODUCTEditPage from "./Component/Product/EditProductData";
import OURMISSIONCREATEPage from "./Component/About_Us/Our_Mission/OurMissionFormCreate";
import OURMISSIONListPage from "./Component/About_Us/Our_Mission/Our_Mission";
import OURMISSIONEditPage from "./Component/About_Us/Our_Mission/OurMissionEdit";
import MISHRAMBHUCREATEPage from "./Component/About_Us/How_mishrambu_started/HowCreate";
import MISHRAMBHUListPage from "./Component/About_Us/How_mishrambu_started/ListHow";
import MISHRAMBHUEditPage from "./Component/About_Us/How_mishrambu_started/EditHowData";
import BEHINDPEOPLECREATEPage from "./Component/About_Us/People_behind/PeopleBehindCreate";
import BEHINDPEOPLEListPage from "./Component/About_Us/People_behind/ListBehindpeople";
import BEHINDPEOPLEEditPage from "./Component/About_Us/People_behind/EditPeopleBehindData";
import TESTIMONIALCREATEPage from "./Component/Testmonial/TestimonialCreate";
import TESTIMONIALListPage from "./Component/Testmonial/ListTestimonial";
import TESTIMONIALEditPage from "./Component/Testmonial/EditTestimonalData";
import MAINCREATEPage from "./Component/Main_Page/AddMainData";
import MAINListPage from "./Component/Main_Page/ListMainData";
import MAINEditPage from "./Component/Main_Page/EditMainData";
import ORDERLIST from "./Component/Order/ListOrder";
import BULKORDERLIST from "./Component/Order/BulkOrder";
import COUPENCREATEPage from "./Component/Coupen/AddCoupenData";
import COUPENListPage from "./Component/Coupen/ListCoupenData";
import COUPENEditPage from "./Component/Coupen/EditCoupenData";
import CONTACTGET from "./Component/ContactGetInTouch/ListGetInTouch";
import CONTACTWITH from "./Component/ContactGetInTouch/ContactWithUs";
import Bulkorderdetail from "./Component/Order/Bulkorderdetail";
import Orderdetail from "./Component/Order/Orderdetail";
import EmailCREATEPage from "./Component/Email/AddEmailData";
import EmailListPage from "./Component/Email/ListEmailData";
import EmailEditPage from "./Component/Email/EditEmailData";
import StoreCREATEPage from "./Component/Store/AddStoreData";
import StoreListPage from "./Component/Store/ListStoreData";
import StoreEditPage from "./Component/Store/EditStoreData";

import TermLISTPAGE from "./Component/Terms_Condition/ListTerm";
import TermCREATEPage from "./Component/Terms_Condition/TermCreate";
import TermEditPage from "./Component/Terms_Condition/EditTermData";
import PrivacyCREATEPage from "./Component/Privacy/PrivacyCreate";
import PrivacyListPage from "./Component/Privacy/ListPrivacy";
import PrivacyEditPage from "./Component/Privacy/EditPrivacyData";


import RefundCREATEPage from "./Component/Refund/RefundCreate";
import RefundListPage from "./Component/Refund/ListRefund";
import RefundEditPage from "./Component/Refund/EditRefundData";


import CancellationCREATEPage from "./Component/Cancellation/CancellationCreate";
import CancellationListPage from "./Component/Cancellation/ListCancellation";
import CancellationEditPage from "./Component/Cancellation/EditCancellationData";



const AuthenticatedRoute = ({ element }) => {
  const isAuthenticated = localStorage.getItem("ADMIN-INFO") !== "";
  return isAuthenticated ? element : <Navigate to="/login" />;
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem("ADMIN-INFO") !== "";
  });

  const navigate = useNavigate();

  useEffect(() => {
    const adminInfo = localStorage.getItem("ADMIN-INFO");
    const currentPath = window.location.pathname;

    if (adminInfo !== "") {
      setIsAuthenticated(true);
    } else if (currentPath !== "/login" && currentPath !== "/forgot-password") {
      setIsAuthenticated(false);
      navigate("/login");
    }
  }, [navigate]);

  return (
    <>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          {/*}  <Route path="/dashboard" element={<Dashboard />} />*/}
          <Route
            path="/dashboard"
            element={<AuthenticatedRoute element={<Dashboard />} />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<Forgotpage />} />
          <Route path="/errorPage" element={<ErrorPage />} />
          <Route path="/contactUsTable" element={<ConatctUsTable />} />

          {/*main route*/}
          {/*General Data */}
          <Route path="/socialIconList" element={<SOCIALICON />} />
          <Route
            path="/socialIconCreateForm"
            element={<SOCIALICONCREATEFORM />}
          />
          <Route
            path="/socialiconEdit/:edit"
            element={<SOCIALICONEDITFORM />}
          />
          <Route path="/newsletterList" element={<NewsletterList />} />
          <Route path="/getInTouchList" element={<GETINTOUCH />} />
          <Route path="/createGetInTouch" element={<CREATEGETINTOUCH />} />
          <Route
            path="/GetInTouchEdit/:edit"
            element={<GETINTOUCHEDITFORM />}
          />
          {/*Cms Data */}
          <Route path="/bannerList" element={<BANNER />} />
          <Route path="/createBanner" element={<CREATEBANNER />} />
          <Route path="/editBannerData/:edit" element={<EDITBANNERDATA />} />
          {/*Cms Data */}
          <Route path="/categoryList" element={<CATEGORY />} />
          <Route path="/createCategory" element={<CREATECATEGORY />} />
          <Route path="/editCategoryData/:edit" element={<EDITCATEGORY />} />
          <Route path="/bLogListPage" element={<BLogListPage />} />
          <Route path="/createblogdata" element={<BlogCREATEPage />} />
          <Route path="/editBlogData/:edit" element={<BlogEditPage />} />

          <Route path="/trickerListPage" element={<TrickerListPage />} />
          <Route path="/createTrickerdata" element={<TrickerCREATEPage />} />
          <Route path="/editTrickerData/:edit" element={<TrickerEditPage />} />

          <Route path="/privacyListPage" element={<PrivacyListPage />} />
          <Route path="/createPrivacydata" element={<PrivacyCREATEPage />} />
          <Route path="/editPrivacyData" element={<PrivacyEditPage />} />
          <Route path="/refundListPage" element={<RefundListPage />} />
          <Route path="/createRefunddata" element={<RefundCREATEPage />} />
          <Route path="/editRefundData" element={<RefundEditPage />} />

          <Route path="/cancellationListPage" element={<CancellationListPage />} />
          <Route path="/createCancellationdata" element={<CancellationCREATEPage />} />
          <Route path="/editCancellationData" element={<CancellationEditPage />} />


          <Route path="/termConditionListPage" element={<TermLISTPAGE />} />
          <Route path="/createTermConditionData" element={<TermCREATEPage />} />
          <Route path="/editTermConditionData/:edit" element={<TermEditPage />} />

          <Route path="/bulkorderDetail" element={<Bulkorderdetail />} />
          <Route path="/orderDetail" element={<Orderdetail />} />

          <Route path="/faqListPage" element={<FAQListPage />} />
          <Route path="/createFaqData" element={<FAQCREATEPage />} />
          <Route path="/editFaqData/:edit" element={<FAQEditPage />} />
          <Route path="/emailListPage" element={<EmailListPage />} />
          <Route path="/createEmaildata" element={<EmailCREATEPage />} />
          <Route path="/editEmailData/:edit" element={<EmailEditPage />} />

          <Route path="/storeListPage" element={<StoreListPage />} />
          <Route path="/createStoredata" element={<StoreCREATEPage />} />
          <Route path="/editStoreData/:edit" element={<StoreEditPage />} />

          <Route path="/productListPage" element={<PRODUCTListPage />} />
          <Route path="/createProductData" element={<PRODUCTCREATEPage />} />
          <Route path="/editProductData/:edit" element={<PRODUCTEditPage />} />

          <Route path="/ourMissionListPage" element={<OURMISSIONListPage />} />
          <Route
            path="/createourMissionData"
            element={<OURMISSIONCREATEPage />}
          />
          <Route path="/productBulkData" element={[<AuthenticatedRoute/>,<PRODUCTBULKDATA />]} />
          <Route
            path="/editourMissionData/:edit"
            element={<OURMISSIONEditPage />}
          />
          <Route
            path="/mishrambuStartedListPage"
            element={<MISHRAMBHUListPage />}
          />
          <Route path="/createStartedData" element={<MISHRAMBHUCREATEPage />} />
          <Route
            path="/editStartedData/:edit"
            element={<MISHRAMBHUEditPage />}
          />
          <Route
            path="/behindPeopleListPage"
            element={<BEHINDPEOPLEListPage />}
          />
          <Route
            path="/createBehindPeopleData"
            element={<BEHINDPEOPLECREATEPage />}
          />
          <Route
            path="/editBehindPeopleData/:edit"
            element={<BEHINDPEOPLEEditPage />}
          />
          <Route
            path="/testimonialListPage"
            element={<TESTIMONIALListPage />}
          />
          <Route
            path="/createTestimonialData"
            element={<TESTIMONIALCREATEPage />}
          />
          <Route
            path="/editTestimonialData/:edit"
            element={<TESTIMONIALEditPage />}
          />
          <Route path="/mainListPage" element={<MAINListPage />} />
          <Route path="/createMainData" element={<MAINCREATEPage />} />
          <Route path="/editMainData/:edit" element={<MAINEditPage />} />
          <Route path="/orderList" element={<ORDERLIST />} />
          <Route path="/bulkOrderList" element={<BULKORDERLIST />} />
          <Route path="/coupenListPage" element={<COUPENListPage />} />
          <Route path="/createCoupenData" element={<COUPENCREATEPage />} />
          <Route path="/editCoupenData/:edit" element={<COUPENEditPage />} />

          <Route path="/contactGetList" element={<CONTACTGET />} />
          <Route path="/contactWithList" element={<CONTACTWITH />} />
          {/*main route*/}

          <Route
            path="/contactUsAddresstable"
            element={<ContatcUsAddressTable />}
          />
          <Route
            path="/contactUsEnquiryTable"
            element={<ContatcUsEnquiryTable />}
          />

          <Route path="/faqList" element={<FaqList />} />

          <Route path="/faqPageList" element={<FaqPageList />} />
          <Route path="/salesOrderEdit" element={<SalesOrderEdit />} />
          <Route path="/homePage" element={<HomePage />} />
          <Route path="/createCategories" element={<CreateCategories />} />

          <Route path="/editProfile" element={<EDITPROFILE />} />
          <Route path="/utlityAnimation" element={<UtlityAnimation />} />
          <Route path="/utlityOther" element={<UtlityOther />} />
        </Routes>
      </div>
    </>
  );
};
export default App;
