import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { updateCoupenformData, getCoupenData } from "../Controller/Coupen";
import AddCoupenpopup from "./Editpopup";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Coupen.css";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";

function EditCoupenData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const { edit } = useParams();
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  // console.log(adminInformation);
  const [formData, setFormData] = useState({
    coupon_code: "",
    discount: "",
    start_date:"",
    start_time:"",
    end_date:"",
    end_time:"",
    quantity:"",
    eligible_amount:"",
    max_amount:"",
  });
  /*************************************************************
   * Function Name  : handleInputChange
   * Created Date   : 10-01-2024
   *************************************************************/

  const handleInputChange = (e, fieldName) => {
    let value;
  
    // Check the type of event and get value accordingly
    if (e.target) {
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    } else {
      value = e; // For ReactQuill or other custom components
    }
  
    // For checkbox specifically
    if (fieldName === "show") {
      value = e; // directly use e as it represents checked status
    }
  
    // Update formData with the new value
    setFormData((prevVal) => ({
      ...prevVal,
      [fieldName]: value,
    }));
  
    // Clear any previous errors for this field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };

  /*************************************************************
   * Function Name  : handleSubmit
   * Purposs        : This function is used add social icon data
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.coupon_code) {
        setErrors((preError) => ({
          ...preError,
          coupon_code: "Coupon code is required.",
        }));
      } else if (!formData.discount) {
        setErrors((preError) => ({
          ...preError,
          discount: "Discount is required.",
        }));
      } else if (!formData.start_date) {
        setErrors((preError) => ({
          ...preError,
          start_date: "Start date is required.",
        }));
      } else if (!formData.start_time) {
        setErrors((preError) => ({
          ...preError,
          start_time: "Start time is required.",
        }));
      } else if (!formData.end_date) {
        setErrors((preError) => ({
          ...preError,
          end_date: "End Date is required.",
        }));
      } else if (!formData.end_time) {
        setErrors((preError) => ({
          ...preError,
          end_time: "End time is required.",
        }));
      } else if (!formData.quantity) {
        setErrors((preError) => ({
          ...preError,
          quantity: "Quantity is required.",
        }));
      } else if (!formData.eligible_amount) {
        setErrors((preError) => ({
          ...preError,
          eligible_amount: "Eligible amount is required.",
        }));
      } else if (!formData.max_amount) {
        setErrors((preError) => ({
          ...preError,
          max_amount: "Max amount is required.",
        }));
      } else {
        setIsSubmitting(true);
        const updateResult = await updateCoupenformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === true) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            coupon_code: "",
            discount: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        } else {
          setErrors((preError) => ({
            ...preError,
            category_name:
              "Category name already exists. Please choose a different name.",
          }));
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  /*************************************************************
   * Function Name  : getCoupenData
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getCoupenfetchData = async () => {
    const options = { id: edit };
    try {
      const result = await getCoupenData(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        // Replace _id with id in the response data
        const modifiedData = { ...responseData, id: responseData._id };
        delete modifiedData._id; // Remove the _id property
        setFormData(modifiedData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCoupenfetchData();
  }, []);
  /*************************************************************
          End Function
   *************************************************************/

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Edit Coupon </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group row">
                        <div className="col-md-6">
                          <label htmlFor="coupon_code">
                            Coupon Code<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section"
                            id="coupon_code"
                            name="coupon_code"
                            placeholder="Enter Coupen Code"
                            value={formData.coupon_code}
                            onChange={handleInputChange}
                          />
                          {errors?.coupon_code ? (
                            <p style={{ color: "red" }}>
                              {errors?.coupon_code}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="discount">
                            Discount<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section"
                            id="discount"
                            name="discount"
                            value={formData.discount}
                            placeholder="Enter Discount"
                            onChange={handleInputChange}
                          />
                          {errors?.discount ? (
                            <p style={{ color: "red" }}>{errors?.discount}</p>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-md-6">
                          <label htmlFor="start_date">Start Date<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="date"
                            className="form-control"
                            id="start_date"
                            name="start_date"
                            placeholder="Enter Sart Date"
                            value={formData.start_date}
                            min={new Date().toISOString().split('T')[0]} // Set the min attribute to today's date
                            onChange={(e) => handleInputChange(e, "start_date")}
                          />
                          {errors?.start_date ? (
                            <p style={{ color: "red" }}>
                              {errors?.start_date}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="start_time">Start Time<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="time"
                            className="form-control"
                            id="start_time" 
                            name="start_time"
                            placeholder="Enter Start Time"
                            value={formData.start_time}
                            onChange={(e) => handleInputChange(e, "start_time")}
                          />
                          {errors?.start_time ? (
                            <p style={{ color: "red" }}>
                              {errors?.start_time}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="end_date">End Date<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="date"
                            className="form-control"
                            id="end_date"
                            name="end_date"
                            placeholder="Enter End Date"
                            value={formData.end_date}
                            onChange={(e) => handleInputChange(e, "end_date")}
                          />
                          {errors?.end_date ? (
                            <p style={{ color: "red" }}>
                              {errors?.end_date}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="end_time">End Time<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="time"
                            className="form-control"
                            id="end_time"
                            name="end_time"
                            value={formData.end_time}
                            placeholder="Enter End Time"
                            onChange={(e) => handleInputChange(e, "end_time")}
                          />
                          {errors?.end_time ? (
                            <p style={{ color: "red" }}>
                              {errors?.end_time}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="quantity">Quantity<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="number"
                            className="form-control"
                            id="quantity"
                            name="quantity"
                            placeholder="Enter Quantity"
                            value={formData.quantity}
                            onChange={(e) => handleInputChange(e, "quantity")}
                          />
                          {errors?.quantity ? (
                            <p style={{ color: "red" }}>
                              {errors?.quantity}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="coupon_code">Eligible Amount<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="number"
                            className="form-control"
                            id="eligible_amount"
                            name="eligible_amount"
                             value={formData.eligible_amount}
                            placeholder="Enter Eligible Amount"
                            onChange={(e) => handleInputChange(e, "eligible_amount")}
                          />
                          {errors?.eligible_amount ? (
                            <p style={{ color: "red" }}>
                              {errors?.eligible_amount}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="coupon_code">Maximum Amount<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="number"
                            className="form-control"
                            id="max_amount"
                            name="max_amount"
                            value={formData.max_amount}
                            placeholder="Enter Maximum Amount"
                            onChange={(e) => handleInputChange(e, "max_amount")}
                          />
                          {errors?.max_amount ? (
                            <p style={{ color: "red" }}>
                              {errors?.max_amount}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="misrambhu_total_btn_for_gap"> 
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                          style={{
                            backgroundColor: "#891621",
                            borderColor: "#5f1e24",
                          }}
                        >
                          {isSubmitting ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Update"
                          )}
                        </button>
                        <Link to="/coupenListPage">
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{
                              backgroundColor: "#891621",
                              borderColor: "#5f1e24",
                            }}
                          >
                            <span>Cancel</span>
                          </button>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddCoupenpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCoupenData;
