import { RiEyeCloseFill } from 'react-icons/ri';
import  { API_ADMIN_URL }  from '../Config/constant';
import {getRequest, postRequest,deleteRequest} from '../Controller/API';
/*********************************************************
 *  This function is use to getSocialDatafetch api
 *********************************************************/
export const getBlogDatafetch = async (options , authToken)=>{
    try{
        const {page,page_name}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'blog/list-blog',
                postData : options,
                headers: {
                  authToken: authToken
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function

export const getBlogDataTotal = async (options , authToken)=>{
  try{
      const {}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'blog/total-blog',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};
export const statusEmailData = async (options , authToken)=>{
  try{
      const {status,id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'blog/status',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};
/*********************************************************
 *  This function is use to getBannerData api
 *********************************************************/
export const getblogdata = async (options , authToken) =>{
  try{
      const {id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'blog/get-blog',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function
 /*********************************************************
 *  This function is use to addBlogformData api
 *********************************************************/
 export const addBlogformData = async (options, authToken) => {
    try {
      const { title, description,blog_image } = options;
      // Create a FormData object
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      formData.append('blog_image', blog_image);
      const posrData = {
        url: API_ADMIN_URL + 'blog/add-blog',
        postData: formData,
        headers: {
          authToken: authToken,
          'Content-Type': 'multipart/form-data',
        },
      };
      const res = await postRequest(posrData);
      if (res) {
        return res;
      } else {
        return { status: false, message: res?.response?.data?.statusMessage };
      }
    } catch (error) {
      return { status: false, message: "Under Maintenance, Please try after some time." };
    }
  };
   // End of Function
   /*********************************************************
 *  This function is use to addsocialicondata api
 *********************************************************/
   export const updateBlogformData = async (options, authToken) => {
    try {
      const { title, description,blog_image, id } = options;
      const postData = {
        url: API_ADMIN_URL + 'blog/edit-blog',
        postData: options,
        headers: {
          authToken: authToken,
          'Content-Type': 'multipart/form-data',
        },
      };
      const res = await postRequest(postData);
  
      if (res && res.status === 200) {
        return res.data; // Assuming your response has a 'data' property
      } else {
        return { status: false, message: res?.data?.statusMessage || 'Request failed.' };
      }
    } catch (error) {
      console.error('Error updating banner:', error);
      return { status: false, message: 'Under Maintenance, Please try after some time.' };
    }
  };
  
 // End of Function

  
/*********************************************************
 *  This function is use to deleteBannerData api
 *********************************************************/
export const deleteBlogDatafetch = async (options ,authToken) => {
  try {
    const { id } = options;
    const postData = {
      id: id,
    };
    const postOptions = {
      url: API_ADMIN_URL + 'blog/delete-blog',
      postData: postData,
      headers: {
        authToken: authToken
      }
    };
    const res = await deleteRequest(postOptions);
    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};
