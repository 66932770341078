import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import "../../Contact Us/ContactUs.css";

const AddSocialIconModel = ({ popUpOpen, togglePopUp }) => {
    const navigate = useNavigate();
  const handleCancel = () => {
    togglePopUp();
    navigate('/getInTouchList');
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 
  return (
    <Dialog
      open={popUpOpen}
      onClose={togglePopUp}
      maxWidth="md"
      PaperProps={{
        className: "myDialogPopUp",
      }}
    >
      <div className="myDrawer">
        <div className="myMainDrawerClass">
          <div>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              Confirmation
            </Typography>
          </div>
          <div>
            <IconButton onClick={togglePopUp}>
              <IoMdClose />
            </IconButton>
          </div>
        </div>
        <Typography
          variant="body1"
          component="div"
          className="my_delete_popup_paragraph"
        >
          <p>Data Added Successfully</p>
        </Typography>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Ok
          </Button>
         {/*} <Button onClick={handleLogout} color="primary">
            Ok
    </Button>*/}
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default AddSocialIconModel;
