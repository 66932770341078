import React, { useState } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Form } from "react-bootstrap";
import { DatePicker, Input, Select } from "antd";
import "./FaqList.css";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";

function FaqList() {
  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/*  <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">FAQ List</h1>
                <p className="mb-4">
                  DataTables is a third party plugin that is used to generate
                  the demo table below. For more information about DataTables,
                  please visit the{" "}
                  <a target="_blank" href="https://datatables.net">
                    official DataTables documentation
                  </a>
                </p>

                <div className="table_head_main_class">
                  <div className="my_antd_date_picker">
                    <Form.Group controlId="formDate">
                      <div className="my_date_picker">
                        <Form.Label>From</Form.Label>
                        <DatePicker />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="my_antd_date_picker">
                    <Form.Group controlId="formDate">
                      <div className="my_date_picker">
                        <Form.Label>To</Form.Label>
                        <DatePicker />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="my_antd_date_picker">
                    <Form.Group controlId="formDate">
                      <div className="my_date_picker">
                        <Form.Label>Filter By</Form.Label>
                        <Select
                          showSearch
                          placeholder="Select a person"
                          optionFilterProp="children"
                          options={[
                            {
                              value: "jack",
                              label: "Jack",
                            },
                            {
                              value: "lucy",
                              label: "Lucy",
                            },
                            {
                              value: "tom",
                              label: "Tom",
                            },
                          ]}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="my_antd_date_picker1">
                    <Form.Group controlId="formDate">
                      <div className="my_date_picker">
                        <Form.Label>Search</Form.Label>
                        <Search placeholder="input search text" />
                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="card shadow mb-4">
                  <div className="card-header py-3 dataBase_table_Add_Data">
                    <h6 className="m-0 font-weight-bold text-primary">
                      FAQ List
                    </h6>
                    <Link to="/faqPageList">
                      <button type="button" className="btn btn-primary">
                        Add Data
                      </button>
                    </Link>
                  </div>
                  <div className="card-body">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaqList;
