import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload, Input } from "antd";
import { addCategoryformData } from "../Controller/Category";
import AddCategoryModel from "./AddCategorypopup";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Category.css";
import { Link } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { SketchPicker } from "react-color";

function AddCategoryData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [formData, setFormData] = useState({
    category_name: "",
    color: "",
    image: null,
  });

  const [openColorPicker, setOpenColorPicker] = useState(true); // Set to true to open on load

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      const file = e.target.files[0];
      setFormData((prevVal) => ({
        ...prevVal,
        [name]: file,
      }));
    } else if (type === "checkbox") {
      setFormData((prevVal) => ({
        ...prevVal,
        [name]: value ? "Yes" : "No",
      }));
    } else {
      setFormData((prevVal) => ({
        ...prevVal,
        [name]: value,
      }));
    }

    setErrors((preError) => ({
      ...preError,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.category_name) {
        setErrors((preError) => ({
          ...preError,
          category_name: "Category name is required.",
        }));
      }
      // else if (!formData.color) {
      //   setErrors((preError) => ({
      //     ...preError,
      //     color: "Category color is required.",
      //   }));
      // }
      else {
        setIsSubmitting(true);
        const updateResult = await addCategoryformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === 200) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            category_name: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        } else {
          setErrors((preError) => ({
            ...preError,
            category_name:
              "Category name already exists. Please choose a different name.",
          }));
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const handleFileChange4 = (fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({ ...prevFormData, image: file }));
    }
  };

  const handleColorChange = (color) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      color: color.hex,
    }));
  };

  const toggleColorPicker = (e) => {
    e.preventDefault();
    setOpenColorPicker(!openColorPicker);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Add Category</h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group">
                        <label htmlFor="fullname">
                          Category Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control common_category_input_for_all_section"
                          id="category_name"
                          name="category_name"
                          placeholder="Enter Category Name"
                          onChange={handleInputChange}
                        />
                        {errors?.category_name && (
                          <p style={{ color: "red" }}>{errors.category_name}</p>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="color">
                          Category Color
                        </label>
                        <div style={{ display: "inline-block" }}>
                          <div
                            onClick={toggleColorPicker}
                            style={{
                              width: "36px",
                              height: "14px",
                              borderRadius: "2px",
                              backgroundColor: formData.color,
                              cursor: "pointer",
                            }}
                          ></div>
                          {openColorPicker && (
                            <div style={{ position: "absolute", zIndex: "2" }}>
                              <SketchPicker
                                color={formData.color}
                                onChange={handleColorChange}
                              />
                            </div>
                          )}
                        </div>
                        {errors?.color && (
                          <p style={{ color: "red" }}>{errors.color}</p>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="image">Image</label>
                        <ImgCrop rotate aspect={310 / 213}>
                          <Upload
                            className="avatar-uploader"
                            name="image"
                            onChange={({ fileList }) =>
                              handleFileChange4(fileList)
                            } // Corrected handleFileChange function
                            listType="picture-card"
                            fileList={null} // You may want to pass formData.right_images here instead of null
                            beforeUpload={() => false}
                            style={{ width: "100%", height: "100%" }}
                          >
                            {formData.image ? (
                              <img
                                src={URL.createObjectURL(formData.image)}
                                alt="subcategory"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <div>
                                <svg
                                  className="icon"
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  data-icon="image"
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M0 64v384h512V64H0zm480 352H32V96h448v320z"
                                  ></path>
                                </svg>
                                <div className="ant-upload-text">Upload</div>
                              </div>
                            )}
                          </Upload>
                        </ImgCrop>
                      </div>
                      <div className="misrambhu_total_btn_for_gap">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                          style={{
                            backgroundColor: "#891621",
                            borderColor: "#5f1e24",
                          }}
                        >
                          {isSubmitting ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Submit"
                          )}
                        </button>
                        <Link to="/categoryList">
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{
                              backgroundColor: "#891621",
                              borderColor: "#5f1e24",
                            }}
                          >
                            <span>Cancel</span>
                          </button>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddCategoryModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCategoryData;
