import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { InboxOutlined } from "@ant-design/icons";
import "../AllCssFile.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Upload } from "antd";
import { useNavigate,Link } from "react-router-dom";
import { updateAboutformData } from "../Controller/Cancellation"; // Update imports accordingly
import { useLocation } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { imageURL } from "../Config/constant";
import AddblogModel from "./EditCancellationpopup";

function EditCancellationData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [selectedItem, setSelectedItem] = useState({}); // Define setSelectedItem

  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [formData, setFormData] = useState({
    id: "",
    title: "",
    description: "",
  });

  const handleInputChange = (value, field) => {
    if (field === "title") {
      setFormData((prevVal) => ({
        ...prevVal,
        title: value,
      }));
    } else if (field === "description") {
      setFormData((prevVal) => ({
        ...prevVal,
        description: value,
      }));
    } else {
      const name = field;
      const inputValue = value;

      setFormData((prevVal) => ({
        ...prevVal,
        [name]: inputValue,
      }));
      setErrors((prevError) => ({
        ...prevError,
        [name]: "",
      }));
    }
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.title) {
        setErrors((preError) => ({
          ...preError,
          title: "Title is required.",
        }));
      }else if (!formData.description) {
        setErrors((preError) => ({
          ...preError,
          description: 'Description is required.',
        }));
      } else {
        setIsSubmitting(true);
        const updateResult = await updateAboutformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === true) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            title: "",
            description: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        } else {
          localStorage.removeItem("TOKEN");
          localStorage.removeItem("ADMIN-INFO");
          navigate("/login");
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const locationState = location.state;
    if (locationState && locationState.selectedItem) {
      setSelectedItem(locationState.selectedItem);
      // Set formData state with selectedItem's values
      setFormData({
        ...formData,
        id: locationState.selectedItem._id,
        title: locationState.selectedItem.title,
        description: locationState.selectedItem.description,
      });
    }
  }, [location.state]);
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <h5>Edit Cancellation Policy Content </h5>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <div className="responsive">
                      <form
                        onSubmit={handleSubmit}
                        encType="multipart/form-data"
                      >
                        <div className="form-group">
                          <label htmlFor="exampleInputText">Title<span style={{ color: "red" }}>*</span></label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="title"
                            name="title" // Make sure the name attribute is set to 'title'
                            value={formData.title}
                            onChange={(e) =>
                              handleInputChange(e.target.value, "title")
                            }
                          />

                          {errors.title && (
                            <p style={{ color: "red" }}>{errors.title}</p>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Description<span style={{ color: "red" }}>*</span>
                          </label>
                          <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={(value) =>
                              handleInputChange(value, "description")
                            }
                          />
                          {errors.description && <p style={{ color: "red" }}>{errors.description}</p>}
                        </div>
                        <button type="submit" className="btn btn-primary" disabled={isSubmitting} style={{
                            backgroundColor: "#891621",
                            borderColor: "#5f1e24",
                          }}>
          {isSubmitting ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Update'}
        </button>
        <Link to="/cancellationListPage">
        <button
    type="button"
    className="btn btn-primary"
    style={{
      backgroundColor: "#891621",
      borderColor: "#5f1e24",
    }}
>
    <span>Cancel</span>
</button>

</Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddblogModel popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
        </div>
      </div>
    </div>
  );
}

export default EditCancellationData;
